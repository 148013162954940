@import "../../../../styles/variables";
.request_new {
    padding: 8px;
    display: inline-block;
}
.action {
    button {
        float: right;
        margin-top: 16px;
        margin-right: 0;
        background: $green;
        border-radius: 5px;
        color: white;
        padding: 14px 24px;
        &:hover {
            background: $green600;
        }
    }
}

.billingTotal {
    width: 100%;
    display: inline-block;
    text-align: left;
    .breakdown {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        span {
            display: inline-block;
            float: right;
            width: auto;
            margin-left: auto;
        }
        div {
            margin-right: 8px;
            height: auto;
            &.total {
                margin-right: 0;
                margin-left: auto;
                background: none;
                font-size: 16px;
                text-align: right;
                padding: 0px 0px 0px 8px;
                border-color: white;
                color: $blue600;
                small {
                    width: 100%;
                }
            }
        }
        small {
            display: inline-block;
            width: auto;
            float: right;
            margin-left: 8px;
            color: grey;
            font-size: 12px;
            strong {
                font-family: "Bagoss Extended";
            }
        }
    }
    span {
        width: 100%;
        display: inline-block;
    }
}
