@import "../../../../styles/variables";
@import "../../../../styles/partials/_animation.module.scss";
@import "../../../../styles/mixins/breakpoint";

.shift_list_item {
    width: 100%;
    padding: 10px 14px;
    box-shadow: #e7e7e7 0px 0px 3px;
    background: white;
    margin-bottom: 8px;
    border-radius: 4px;
    border-bottom: #efefef 1px solid;

    &:hover {
    }
    &.view_only {
        &:hover {
            border-color: white;
        }
    }
    strong {
        font-weight: 500;
    }
    small {
        color: #696969;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .location_image {
        width: 50px;
        padding: 4px 0 0 16px;
        display: table;
        float: left;
        @include breakpoint($tablet) {
            display: inline-block;
            float: none;
        }
    }
    .time {
        display: flex;
        align-items: center;
        svg {
            margin-right: 8px;
        }
    }
    .date_time {
        border-right: 1px solid #cecece;
        width: 150px;
        text-align: center;
        padding-right: 16px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        float: left;
        @include breakpoint($tablet) {
            float: none;
        }
        svg {
            max-width: 20px;
        }
    }

    .wage {
        padding: 0 16px;
        height: 100%;
    }
    .location {
        width: 200px;
        padding: 0 16px 0 8px;
        margin-right: auto;
        text-align: left;
        display: table;
        float: left;
        @include breakpoint($tablet) {
            display: inline-block;
            float: none;
        }
    }
    .wage {
        width: 150px;
        display: table;
        text-align: right;
        border-right: 1px solid #cecece;
        @include breakpoint($tablet) {
            display: inline-block;
            float: none;
        }
    }
    .action {
        @include breakpoint($tablet) {
            margin-left: auto;
            float: none;
            width: 200px;
            padding-top: 0;
            justify-content: right;
        }
        text-align: center;
        padding-top: 16px;
        align-items: center;
        justify-content: center;
        width: 200px;
        display: flex;
    }
    .taker {
        @include breakpoint($tablet) {
            max-width: 180px;
            float: none;
            display: inline-block;
            padding-left: 16px;
            padding-right: 8px;
            margin-top: 0;
        }
        margin-top: 16px;
        display: table;
        float: left;

        width: 100%;
    }
}
