.job_question {
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    label {
        font-size: 14px;
        margin-bottom: 8px;
        margin-top: 8px;
        display: inline-block;
        font-family: "Bagoss Condensed";
    }
    textarea {
        padding: 8px;
        font-family: "Bagoss Extended";
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        min-height: 80px;
    }
}

.offergrid {
    width: 100%;
    td {
        padding: 12px 8px;
        &:first-child {
            padding-left: 0;
        }
        strong {
            font-family: "Bagoss Condensed";
        }
    }
    tr {
        border-bottom: 1px solid #cecece;
        &:last-child {
            border-bottom: 0;
            td {
                padding-bottom: 8px;
            }
        }
    }
}

.rejection {
    textarea {
        padding: 8px;
        font-family: "Bagoss Extended";
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        min-height: 80px;
    }
}
.button_cont {
    display: flex;
    padding: 16px 0;
    button {
        width: 50%;
        &:last-child {
            margin-right: 0;
        }
    }
}
