@import "../../../../styles/variables";
.request_new {
    padding: 8px;
    display: inline-block;
}
.action {
    button {
        float: right;
        margin-top: 16px;
        margin-right: 0;
        background: $green;
        border-radius: 5px;
        color: white;
        padding: 14px 24px;
        &:hover {
            background: $green600;
        }
    }
}
