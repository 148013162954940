.description_table {
    width: 100%;
    text-align: left;
    .spanner {
        background: #eaeaea;
        color: grey;
        font-size: 14px;
        &:hover {
            background: #eaeaea;
        }
        td {
            padding: 8px 16px;
        }
    }
    thead {
        border-bottom: 1px solid #cecece;
        tr {
            &:hover {
                background: initial;
            }
        }
    }
    tr {
        &:hover {
            background: #f2f2f2;
        }
    }
    td {
        padding: 16px 16px;
        small {
            color: grey;
        }
        &.column_name {
            width: 320px;
            h3,
            h2,
            small {
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
            }
        }
        .svg-inline--fa {
            margin-right: 16px;
            color: green;
        }
        &.column_actions,
        &.column_sites {
            text-align: right;
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #cecece;
        }
    }
    th {
        font-weight: 800;
        padding: 16px;
        &.column_actions,
        &.column_sites {
            text-align: right;
        }
    }

    td,
    th {
        .avatar {
            float: left;
        }
    }
}

.invoice_table {
    width: 100%;
    text-align: left;
    .spanner {
        background: #eaeaea;
        color: grey;
        font-size: 14px;
        &:hover {
            background: #eaeaea;
        }
        td {
            padding: 8px 16px;
        }
    }
    thead {
        border-bottom: 1px solid #cecece;
        tr {
            &:hover {
                background: initial;
            }
        }
    }
    tr {
        &:hover {
            background: #f2f2f2;
        }
    }
    td {
        padding: 8px 16px;
        small {
            color: grey;
        }
        &.column_name {
            width: 320px;
            h3,
            h2,
            small {
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
            }
        }
        .svg-inline--fa {
            margin-right: 16px;
            color: green;
        }

        &.column_actions,
        &.column_sites {
            text-align: right;
        }
    }
    .column_actions {
        border-left: 1px solid #cecece;
        background: #e0e0e0;
        text-align: center !important;
        min-width: 32px;
        padding: 4px 8px;
        svg {
            max-width: 22px;
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #cecece;
        }
    }
    th {
        font-weight: 800;
        padding: 16px;
        &.column_actions,
        &.column_sites {
            text-align: right;
        }
    }

    td,
    th {
        .avatar {
            float: left;
        }
    }
    .invoice_sub {
        width: 100%;
        tr {
            &.grand_total {
                border-top: 1px solid #cecece;
                font-size: 22px;

                td {
                    padding: 16px 8px 8px 16px !important;
                }
            }
            td {
                text-align: right;
                padding: 8px;
                &.item {
                    padding: 8px 16px;
                    font-family: "Bagoss Condensed";
                    text-align: left;
                }
                &.value {
                }
            }
        }
    }
}

.timesheet_table {
    width: 100%;
    thead {
        border-bottom: 1px solid #cecece;
        tr {
            &:hover {
                background: initial;
            }
        }
    }
    tr {
        &:hover {
            background: #f2f2f2;
        }
    }
    td {
        padding: 16px 16px;
        small {
            color: grey;
        }
        &.column_name {
            width: 320px;
            h3,
            h2,
            small {
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
            }
        }
        .svg-inline--fa {
            margin-right: 16px;
            color: green;
        }
        &.column_actions,
        &.column_sites {
            text-align: right;
        }
    }
    .hours {
        width: 200px;
    }
    .shift_date {
        width: 200px;
    }
    .status {
        width: 20px;
    }
    .site {
        width: 300px;
    }
    tr {
        border-bottom: 1px solid #cecece;
        position: relative;
    }

    th {
        font-weight: 800;
        padding: 16px;
        text-align: left;
        &.column_actions,
        &.column_sites {
            text-align: right;
        }
    }

    td,
    th {
        .avatar {
            float: left;
        }
    }
}

.fixed_hover {
    background: #f2f2f2;
}
.breaker {
    td {
        padding: 8px 16px;
        width: 100%;
        margin-bottom: 8px;
        border-bottom: 2px solid #084320;
        background: #006a62;
        color: white;
    }
}

.spacer {
    td {
        padding: 8px;
    }
}
