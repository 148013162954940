p {
    font-size: 16px;
    strong {
        font-weight: 500;
        font-family: "Bagoss Condensed";
    }
}
h1 {
    font-size: 30px;
    font-family: "Bagoss Condensed";
}
h2 {
    font-size: 22px;
    font-family: "Bagoss Condensed";
}
h3 {
    font-size: 18px;
    font-family: "Bagoss Condensed";
}

.no_overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
    position: relative;
    white-space: nowrap;
}
.align {
    &-left {
        text-align: left;
    }
    &-right {
        text-align: right;
    }
}
.mt {
    &-0 {
        margin-top: 0px;
    }
    &-8 {
        margin-top: 8px;
    }
    &-16 {
        margin-top: 16px;
    }
    &-32 {
        margin-top: 32px;
    }
}
.ml {
    &-0 {
        margin-left: 0px;
    }
    &-8 {
        margin-left: 8px;
    }
    &-16 {
        margin-left: 16px;
    }
}
.mr {
    &-0 {
        margin-right: 0px;
    }
    &-8 {
        margin-right: 8px;
    }
    &-16 {
        margin-right: 16px;
    }
}
.mb {
    &-0 {
        margin-bottom: 0px;
    }
    &-8 {
        margin-bottom: 8px;
    }
    &-16 {
        margin-bottom: 16px;
    }
    &-32 {
        margin-bottom: 32px;
    }
}
ul {
    font-size: inherit;
    margin: 8px 0;
    li {
        font-size: inherit;
    }
}
small {
    width: 100%;
    float: left;
    font-size: 14px;
    line-height: 20px;
    strong {
        font-weight: 500;
        font-family: "Bagoss Condensed";
    }
    &.title {
        display: block;
    }
    &.gutter {
        width: 100%;
        display: inline-block;
        border-top: 1px solid #cecece;
        margin-top: 8px;
        padding-top: 8px;
        color: grey;
        font-size: 12px;
        font-weight: 300;
    }
}
