@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 21px;
  }
}
