@import "../../../styles/variables";

.button {
    transition: $transition;
    background: $blue;
    display: inline-block;
    border-radius: 5px;
    color: $white;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    vertical-align: top;
    line-height: 18px;
    svg {
        max-width: 24px;
        max-height: 18px;
        display: inline-block;
        color: inherit;
        vertical-align: middle;
        path {
            fill: black;
        }
    }
    min-height: 36px;
    cursor: pointer;
    font-family: inherit;
    letter-spacing: 0.5px;
    color: white;
    min-width: 100px;
    padding: 8px 16px;
    border: 1px solid $blue;

    &:disabled {
        cursor: default;
    }
    &:active {
        background: $dark-blue;
    }
    &:hover {
    }
    &:disabled {
        opacity: 0.4;
        background: whitesmoke;
        color: grey;
        border: grey 2px solid;
    }
    svg {
        width: auto;
        height: 100%;
        fill: #fff;
    }
}

.archive {
    background: #ea5143;
    border: 2px solid #ea5143;
    width: auto;
    min-width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    padding: 0;
    svg {
        max-width: 18px;
        path {
            fill: white;
        }
    }
    &:hover {
        background: $red400;
        border: 2px solid $red400;
    }
}
.hollow {
    background: none !important;
    border: 1px solid $blue;
    color: $blue;
    &:hover {
        background: $blue !important;
        color: white;
        border: 1px solid $blue !important;
    }
}

.qr {
    width: 36px;
    min-width: 32px;
    padding: 0;
    background: $green !important;
    border-color: $green;
    &:hover {
        background: $green600 !important;
    }

    svg {
        width: 100%;
        vertical-align: top;
        path {
            fill: white;
        }
    }
}
.secondary {
    background: none !important;
    border: 2px solid $blue;
    color: $blue;
    font-size: 12px;
    padding: 6px 8px;
    min-height: 0;
    &:hover {
        background: $blue !important;
        color: white;
        border: 2px solid $blue !important;
    }
}
.link {
    background: none !important;
    border: 0px solid $blue;
    color: $blue;
    font-size: inherit;
    padding: 0px 0px;
    width: auto;
    min-width: 0;
    display: inline;
    &:hover {
        background: none !important;
        color: $blue;
        text-decoration: underline;
        border: none !important;
    }
}

.micro {
    padding: 4px 8px !important;
    width: auto;
    min-width: 36px;
    height: 36px;
    font-size: 14px !important;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
}
.error {
    background: $red !important;
    border: 1px solid $red !important;
}
.big {
    height: 48px;
    font-size: 18px;
}

.small {
    height: 28px;
    font-size: 14px;
    border-radius: 6px;
}
.light {
    border-color: $blue;
    background: $white;
    color: $blue;
    &:hover {
        background: $blue;
        border-color: $blue;
        color: white;
    }
}

.selected {
    background: $blue;
}

.green {
    background: $green;
    border-color: $green;
}
