@import "../../styles/variables";
@import "../../styles/partials/_animation.module.scss";
@import "../../styles/mixins/breakpoint";

.slingerContainer {
    .page_inner {
        width: 100%;
        min-height: 100vh;
        @include breakpoint($tablet) {
            width: calc(100% - 280px);
            height: 100vh;
        }

        overflow: scroll;
        float: left;
        position: relative;
        background: #f7f9fb;
        display: inline-block;

        &.registration {
            width: 100%;

            .page_content {
                max-width: 960px;
                width: 100%;
                margin: 0 auto;
                position: relative;
                display: table;
                padding: 80px 20px;
            }
        }
        .floppy_banner {
            background: $blue;
            width: 100%;
            position: relative;
            float: left;
            height: 8px;
        }
    }

    .page_content {
        padding: 16px;
        width: 100%;
        overflow: auto;
        min-height: 80vh;
    }
}
