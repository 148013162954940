@import "../../../../styles/variables";
@import "../../../../styles/partials/_animation.module.scss";
.notificationBell {
    min-width: 32px;
    text-align: center;
    height: 38px;
    position: relative;
    margin-left: 16px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    //background: #f1f1f1;
    cursor: pointer;
    &:hover {
        svg {
            fill: $green;
        }
    }
    svg {
        max-width: 22px;
        width: 100%;
        height: auto;
        fill: $black;
    }
    .notifydot {
        background: #ff6b00;
        font-size: 8px;
        color: white;
        border-radius: 100%;
        padding: 4px;
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        bottom: 5px;
        right: -2px;
    }
}
