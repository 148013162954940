@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.header {
    background: #f7f9fb;
    width: 100%;
    display: flex;
    padding: 6px 16px;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0;
    }
    h3 {
        font-weight: 400;
        font-size: 18px;
        display: none;
        @include breakpoint($tablet) {
            display: block;
        }
        img {
            margin-right: 16px;
            max-width: 32px;
        }
    }
}

.mobile_menu {
    width: 32px;
    margin-right: 12px;
    height: 32px;
    display: inline-block;
    margin-left: 10px;
    overflow: visible;
    position: relative;
    margin-top: 4px;
    cursor: pointer;
    &.open {
        &:before {
            transform: rotate(45deg);
            top: 13px;
        }
        &:after {
            bottom: -4px;
            height: 4px;
            background: $white;
        }
        i {
            transform: rotate(-45deg);
            top: 13px;
        }
    }
    &:before {
        top: 0px;
    }
    &:after {
        bottom: 2px;
        top: auto;
    }
    i {
        top: 13px;
    }
    &:before,
    &:after,
    i {
        height: 5px;
        background: $black;
        width: 100%;
        border-radius: 3px;
        display: inline-block;
        position: absolute;
        content: "";
        transition: $transition;
    }

    @include breakpoint($tablet) {
        display: none;
    }
}
.menus {
    padding: 8px 16px 8px 0;
    div {
        float: left;
    }
}

.peripheral {
    display: flex;

    padding-right: 16px;
}
