@import '../../../styles/variables';
@import '../../../styles/partials/_animation.module.scss';


.site_card{
    background:$white;
    border-radius:8px;
    padding:16px 0 0;
    display:flex;
    width:auto;
    position:relative;
    font-size:14px;
    .rating{
        width: 100%;
        display:inline-block;margin-left:-2px;
    }
    .site_image{
        width:64px;
        height:64px;
        background-color:#dfa932;
        background-size:cover;
        background-position:center;
        border-radius: 8px;
        min-width: 64px;
        margin-right:16px;
    }
    
    &:hover{
       
    }  
}

span{
    &.site_name{
        width:100%;
        font-weight:500;
        font-size:16px;
    }
    &.address{
        color:#c1c1c1;
        width:100%;
        display:inline-block;
    }
    &.problem{
        color:red;
        font-weight:500;
        font-size: 12px;
    }
}

.float{
    position:absolute;
    right:0;
    top:16px;
    width: 80px;
    text-align:right;
    button{
        margin-bottom:8px;
    }
}
