@import "../../../styles/variables";
.image {
    width: 200px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    height: 200px;
    margin: 20px auto -4px;
    background-color: #f2f2f2;
    background-size: cover !important;
    background-position: center !important;
    border: 3px solid #cecece;
    &.square {
        width: 100%;
        border-radius: 8px;
    }
}

.uploadButton {
    width: 100%;
    text-align: center;
    cursor: pointer;
    span {
        transition: all 0.2s ease-in-out;
        background: $blue;
        display: block;
        border-radius: 0px 0px 8px 8px;
        color: #fff;
        height: 36px;
        line-height: 32px;
        letter-spacing: 0.5px;
        font-size: 12px;
        color: white;
        margin: 0 auto;
        min-width: 100px;
        font-family: inherit;
        padding: 0 16px;
        border: 2px solid $blue;
        border-bottom: 2px solid $dark-blue;
        &:hover {
            border: 2px solid $dark-blue;
        }
    }
}
