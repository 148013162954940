.job_question {
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.pill_list {
    div {
        padding: 4px 6px;
        margin-right: 8px;
        font-size: 12px;
    }
}
.groupType {
    margin-bottom: 16px;
    display: table;
    &:last-child {
        margin-bottom: 0;
    }
    .header {
        h3 {
            margin-bottom: 4px;
        }
    }
}
