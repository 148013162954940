@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";
.shift_list {
    width: 100%;
    &_big {
    }
    table {
        width: 100%;
    }
    .date_title {
        padding: 8px 16px;
        border-radius: 8px;
        margin-top: 16px;
        display: inline-block;
        width: 100%;
        margin-bottom: 8px;
        border-bottom: 2px solid #084320;
        background: $dark-green;
        color: white;
        &:first-child {
            margin-top: 0;
        }
    }
}
