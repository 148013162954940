.archive {
    padding: 8px;
    min-width: 800px;
    h3 {
        margin-bottom: 8px;
    }

    .cancel {
        padding-top: 16px;
    }

    .rack {
        padding: 8px 16px;
        background: #f8fafc;
        border: 2px solid #e9edf5;
        border-radius: 5px;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
        .header {
            padding: 8px 0 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                margin-bottom: 0;
            }
            small {
                margin-top: 4px;
            }
            .toggler {
                width: auto;

                text-align: center;
                border-radius: 3px;

                display: inline-block;
                height: 18px;
                cursor: pointer;
                svg {
                    width: 18px;
                    height: 18px;
                }
                &:hover {
                    background: white;
                }
            }
        }
        .content {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    display: inline-block;
                    margin-right: 16px;
                }
            }

            .toggle {
                cursor: pointer;
                padding: 4px 8px;
                border: 2px solid #ccc;
                border-radius: 5px;
                display: inline-block;
                margin: 5px 0;
                font-size: 14px;
                transition: all 0.3s ease-in-out;
                span {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    transition: all 0.2s ease-in-out;
                    line-height: 14px;
                    text-align: center;
                    vertical-align: middle;
                    margin-top: -3px;
                    font-size: 12px;
                    border-radius: 3px;
                    margin-right: 8px;
                    color: #f8fafc;
                    border: 2px solid #cecece;
                    font-size: 12px;
                }
                &:hover {
                    background: white;
                    border-color: #007bff;
                }
                &.active {
                    background-color: #fff;

                    border-color: #007bff;
                    span {
                        background: #007bff;
                        border-color: #007bff;
                        color: white;
                    }
                }
            }
        }
    }
}
