button {
    border-radius: 24px;
    border-radius: 8px;
    padding: 16px 24px;
    border: none;
    margin-right: 8px;
    color: #000;
    font-family: "Bagoss Extended";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:disabled {
        opacity: 0.7;
        cursor: default;
        color: #cecece;
        &:hover {
            background: #fafafa;
        }
    }
    &:hover {
        background: #cecece;
    }
    &.active {
        background: #63b9e9;
        color: white;
    }
}
