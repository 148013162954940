// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 16px;
$line-height-base: 1.6;

// Colors

$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #ea5143;
$dark-red: #af130c;
$orange: #ed7d31;
$yellow: #edad30;
$green: #019e3a;
$dark-green: #006a62;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #282828;
$dark-grey: #3b3838;
$grey: #cecece;
$white: #fff;

/* Slinger Colours */
$grey200: #f1f1f1;
$grey400: #cecece;
$grey600: #3b3838;

$red100: rgb(255, 207, 207);
$red200: rgb(255, 168, 168);
$red400: rgb(216, 57, 57);
$red600: #af130c;

$green200: #e2f0ea;
$green400: #019e3a;
$green600: #006a62;

$gold200: #fdedca;
$gold400: #faaf00;
$gold600: #b98100;

$blue: #3490dc;
$blue200: #73befb;
$blue400: #3490dc;
$blue600: #235e8f;
$dark-blue: #235e8f;

$transition: all 0.2s ease-in-out;
// Breakpoint names
$mobile: "mobile";
$tablet: "tablet";
$desktop: "desktop";
$desktop-lg: "desktop-large";

// Breakpoints array used for the breakpoint mixin
$breakpoints: (
    $mobile: (
        min-width: 467px,
    ),
    $tablet: (
        min-width: 767px,
    ),
    $desktop: (
        min-width: 992px,
    ),
    $desktop-lg: (
        min-width: 1200px,
    ),
) !default;
