.archive {
    padding: 8px;
    h3 {
        margin-bottom: 8px;
    }

    .cancel {
        padding-top: 16px;
    }
}
