.qrCode {
    background: white;
    width: 500px;
    padding: 16px;
    .data {
        display: flex;
        column-gap: 16px;
        .profile {
            width: 50%;
        }
        .qr {
            width: 50%;
        }
    }
    .title {
        width: 100%;
        padding: 0px 0 16px;

        display: flex;

        small {
            margin-top: 4px;
            color: grey;
        }
        .close {
            float: right;
            cursor: pointer;
            padding: 4px;
            margin-left: auto;
        }
    }
    .warning {
        small {
            font-size: 12px;
            line-height: 16px;
        }
    }
}
