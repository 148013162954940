@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";
.sidebar {
    width: 280px;
    background: #f7f9fb;
    min-width: 220px;
    height: 100vh;
    float: left;
    position: relative;

    display: none;
    @include breakpoint($tablet) {
        display: inline-block;
    }
    &.open {
        display: inline-block;
        width: 100%;
        position: fixed;
        z-index: 100;
        top: 60px;
        padding: 0 16px;
        left: 0;
        .logo {
            display: none;
        }
    }
    .notification_reminder {
        a {
            background: $green;
            color: white;
            text-align: center;
            font-size: 14px;
            padding: 6px;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .submenu {
        display: none;
        padding-left: 46px;
        padding-right: 8px;

        border-radius: 8px;
        width: 100%;
        padding-bottom: 8px;
        li {
            padding: 0px 0 4px;
            a {
                padding: 8px 8px;
                font-size: 14px;
                line-height: 18px;
                &:after {
                    position: absolute;
                    right: 8px;

                    opacity: 1;
                }
                &:hover {
                    background: rgba(27, 158, 59, 60%);
                    color: white;
                    &:after {
                        opacity: 1;
                    }
                }
                &.disabled {
                    opacity: 0.4;
                    cursor: default;
                }
                small {
                    width: 100%;
                    display: inline-block;
                    margin-top: -4px;
                    float: left;
                }
            }
        }
    }

    .active {
        background: rgba(0, 158, 58, 0.1);
        border: #009e3a 1px solid;
        border-radius: 8px;
        margin: 8px 0;
        a {
            color: #018933;
            &:hover {
                background: none;
            }
        }
        svg {
            color: $green;
            path {
                fill: #009e3a !important;
            }
        }
        .submenu {
            display: inline-block;
        }
    }
}

.logo {
    width: 100%;
    text-align: left;
    padding: 12px 16px 6px;

    img {
        max-width: 160px;
    }
}

.menu {
    padding: 10px 16px 32px 16px;
    ul {
        padding: 0;
        margin: 0;
        vertical-align: top;
        list-style: none;
    }
    &:after {
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background: #dadee8;
        margin-top: 16px;
    }
    li {
        padding: 0px 0px;
        a {
            font-size: 16px;
            position: relative;
            color: #282828;
            width: 100%;
            padding: 16px 16px;
            text-decoration: none;
            display: flex;
            align-items: center;

            border-radius: 4px;

            transition: $transition;

            &:hover {
                background: #efefef;
            }
            span {
                vertical-align: middle;
                line-height: 20px;
                user-select: none;
            }
            div {
                width: 24px;
                height: 24px;
                background: $green;
                color: white;
                border-radius: 4px;
                display: inline-block;
                position: relative;
                margin-left: auto;
                font-size: 12px;
                line-height: 25px;
                text-align: center;
            }
            svg {
                height: auto;
                width: 22px;
                vertical-align: middle;
                margin-right: 16px;

                transition: $transition;
                path {
                    fill: $black;
                }
            }
            &:hover {
                text-decoration: none;
                svg {
                    color: $green;
                }
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        li {
            padding-left: 16px;
        }
    }
}

.version {
    font-size: 12px;
    color: $grey;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 16px 12px;
}
