.avatar {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: #eaeaea;
    overflow: hidden;
    box-shadow: #e7e7e7 0px 0px 3px;
    &.tiny {
        max-width: 36px;
    }
    &.small {
        max-width: 56px;
    }
    &.half {
        width: 50%;
        min-width: 100px;
    }
    &.fixed_height {
        height: auto;
    }
    svg {
        width: 100%;
        float: left;
        opacity: 0;
        height: auto;
    }
}
