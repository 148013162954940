@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.question_header {
    padding: 16px 16px 0;
    display: inline-block;
    width: 100%;
}
.question_list {
    padding: 16px;
    .question {
        width: 100%;
        border-bottom: 1px solid #cecece;
        padding: 16px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
        .question_row {
            display: flex;
            width: 100%;
            align-items: center;
            column-gap: 16px;
            input {
                width: 100%;
                background: #f7f7f7;
                padding: 8px 8px;
                border: 1px solid #cecece;
                border-radius: 4px;
            }

            .question_delete {
                margin-left: auto;
                button {
                    background: none;
                    width: 20px;
                    height: 40px;
                    padding: 0 8px 0 0;
                    float: right;
                    border: none;
                    color: red;
                    right: 0px;
                    &:active {
                        svg {
                            color: #282828;
                            fill: black;
                        }
                    }
                    svg {
                        width: 20px;
                        color: red;
                        fill: red;
                        height: 20px;
                    }
                }
            }
        }
        label {
            width: 100%;
            display: inline-block;
            color: grey;
            font-size: 14px;
            margin-bottom: 4px;
        }
    }
}
.question_gutter {
    background: $blue;
    color: white;
    width: 100%;
    border-radius: 0 0 6px 6px;
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    button {
        width: 180px;
        background: white;
        color: #282828;
    }
}
