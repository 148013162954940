.datefilter {
    position: relative;
    height: 48px;
    display: flex;
    padding: 8px 8px 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
}

.micro_list {
    border-collapse: collapse;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    * {
        position: relative;
    }

    th,
    td {
        padding: 6px 12px;
        &.width_300 {
            width: 300px;
        }
        &.width_200 {
            width: 200px;
        }
        &.width_150 {
            width: 150px;
        }
        &.width_100 {
            width: 120px;
        }
        &.width_50 {
            width: 50px;
        }
        &.actionable {
            text-align: right;
        }
        .aligned {
            display: flex;
            align-items: center;
            span {
                padding-left: 8px;
            }
        }
        small {
            font-size: 12px;
        }
    }
    thead {
        tr {
            height: 30px;
            background: #36304a;
            th {
                text-align: left;
                font-size: 14px;
                color: #fff;
                line-height: 1.2;
                font-weight: unset;
            }
        }
    }
    tbody {
        tr {
            height: 50px;
            td {
                font-size: 14px;
                color: #36304a;
                line-height: 1.2;
                font-weight: unset;
            }
            &:nth-child(even) {
                background-color: #f5f5f5;
            }
        }
    }
}
