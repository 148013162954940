@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.blob {
    vertical-align: top;
    @include breakpoint($tablet) {
    }
    display: inline-block;
    h5 {
        font-weight: 500;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 8px;
        font-family: "Bagoss Condensed";
        img {
            vertical-align: middle;
        }
    }
    .notice {
        width: 100%;
        font-size: 12px;
        margin-top: 8px;
    }

    h2 {
        svg {
            display: inline-block;
            vertical-align: top;
            margin-right: 4px;
        }
        margin-bottom: 8px;
    }
}

.flat {
    .blobInner {
        h5 {
            padding: 12px 16px;
        }
        padding: 0;
    }
}
.full {
    width: 100%;
    padding: 0 0px 16px 0;
}
.half {
    width: 100%;
    @include breakpoint($tablet) {
        width: 50%;
    }
}
.third {
    width: 33.333%;
}
.twoThird {
    width: 66.6%;
}
.quarter {
    width: 25%;
}

.threeQuarter {
    width: 75%;
}
.blobInner {
    background: #fff;
    display: table;
    width: 100%;

    border-radius: 5px;

    padding: 16px 19px;
    border: 1px solid #dadee8;
}
