@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";

.loading {
    h3 {
        font-weight: 400;
        clear: both;
        font-size: 18px;
        margin-top: 16px;
    }
    &.internal {
        opacity: 1 !important;
    }
    &.withFade {
        position: absolute;
        top: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        opacity: 0.7;
        z-index: 100;
    }
    @keyframes pulse-green {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 #63b9e9;
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
        }
    }
    .loader {
        width: 96px;

        border-radius: 20px;
        background: #63b9e9;
        box-shadow: 0 0 0 0 #63b9e9;
        animation: pulse-green 2s infinite;
        position: relative;

        @keyframes flipX {
            0%,
            49.99% {
                transform: scaleX(1);
            }
            50%,
            100% {
                transform: scaleX(-1);
            }
        }

        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
