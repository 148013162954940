@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";
.shift_list {
    width: 100%;

    &_big {
    }
    .date_title {
        padding: 8px 16px;
        border-radius: 4px;
        margin-top: 16px;
        display: inline-block;
        width: 100%;
        margin-bottom: 8px;

        background: $green600;
        border: 1px solid $green600;
        color: white;

        &:first-child {
            margin-top: 0;
        }
    }
}
