@import "../../../../styles/variables";
@import "../../../../styles/partials/_animation.module.scss";
@import "../../../../styles/mixins/breakpoint";

.jobOfferModal {
    padding: 16px;
    .title {
        width: 100%;
        padding: 0px 0 16px;

        display: flex;

        small {
            margin-top: 4px;
            color: grey;
        }
        .close {
            float: right;
            cursor: pointer;
            padding: 4px;
            margin-left: auto;
        }
    }
    .pickaside {
        display: flex;
        column-gap: 20px;
        width: 100%;
    }
    .data {
        display: none;
    }
    .reject,
    .offer {
        width: 50%;
        border-radius: 8px;
        padding: 16px;

        small {
            margin: 16px 0;
            width: 100%;
            display: inline-block;
        }
        button {
            border: 1px solid $green;
            height: 40px;
            padding: 0;
        }
    }
    .offer {
        background: $green200;
        border: 1px solid $green;

        button {
            background: $green;
            width: 100%;
            color: white;
            &:hover {
                border: 1px solid $green600;
            }
        }
    }
    .reject {
        background: $red100;
        border: 1px solid $red;

        button {
            background: $red;
            border: 1px solid $red;
            width: 100%;
            color: white;
            &:hover {
                border: 1px solid $red600;
            }
        }
    }
}
