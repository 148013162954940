@import '../../../styles/variables';
@import '../../../styles/partials/_animation.module.scss';


.shift_card{
    background:$white;
    border-radius:8px;
    width:auto;
    margin-bottom:16px; 
    cursor:pointer;
    font-size:14px;
    border-left:8px solid #e86e1e;
    &:hover{
        box-shadow:$grey 0px 5px 5px;
    }  
}

.shift_info{
    padding:16px;
    display:flex;
    width:100%;
    span{
        margin-bottom:8px;
    } 
    .calendar{
        margin-right:16px;
        border-radius:8px;
        overflow: hidden;
        border:1px solid $grey;
        display:table;
        position: relative;
        padding-bottom: 18px;
        width:72px;
        height:60px;
        span{
            text-align: center;
            font-size:28px;
            width:100%;
            display:inline-block;
            padding-top:4px;
            sub{
                font-size: 12px;
                padding-top: 0;
                display: inline-block;
                vertical-align: baseline;
            }
        }
        i{
            position:relative;
            font-size:12px;
            color:black;
            text-align: center;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            width:100%;
            border-radius:0 0 8px 8px;
            display:inline-block;
        }
    }

    .time{
        span{   
            margin-right:4px;
        }
    }
}
.acceptance_status{
    background: $grey;
    font-size: 12px;
    text-align: left;
    color: #1f1f11;
    padding: 12px;
    border-radius: 0 0 8px 0;
}
