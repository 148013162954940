@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.requirements {
    padding: 16px;
}
.outcome {
    background: #f3f3f3;
    border-radius: 5px;
    padding: 8px;
}
.content {
    small {
        margin-top: 4px;
        margin-bottom: 0px;
    }
}
.outcome_list {
    padding: 0;
    background: rgb(241, 241, 241);
    border-radius: 4px;
    padding: 8px;
    margin: 0;
    margin-top: 8px;
    border: 1px solid #dadada;
    list-style: none;
    display: inline-block;
    width: 100%;
    li {
        align-items: top;

        padding: 4px 0;
        margin: 0 0 8px 0;
        display: table;
        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        .head {
            display: flex;
            width: 100%;
        }
        .emoji {
            font-size: 20px;
            margin-right: 8px;
        }
        small {
            font-size: 12px;
            line-height: 16px;
        }
        h5 {
            margin: 0 !important;
            padding: 0 !important;
            border-bottom: none;
            font-weight: 600;
            font-size: 14px;
            font-family: "Bagoss condensed";
        }
    }
}
.component {
    h3 {
        text-transform: capitalize;
        font-size: 16px;
        margin-bottom: 8px;
    }
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
}
