@import '../../../styles/variables';
@import '../../../styles/partials/_animation.module.scss';


.submenuItem{
    width:100%;
    display:inline-block;

    border-bottom:1px solid #f5f5f5;
    box-sizing:border-box;
    font-size:14px;
    transition:$transition;
    a{
        width:100%; color:black; display:inline-block; padding:8px 16px;
        &:hover{
            text-decoration:none;
            color:$green;
        }
    }
    &:hover{
        background:#f5f5f5;
    }
}
