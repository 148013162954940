@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";
.pill {
    padding: 8px 16px 8px;
    display: inline-block;
    line-height: auto;
    color: white;
    border-radius: 5px;
    color: $green;
    border: 2px solid $green;
    background: #cdf1da;

    &.primary {
    }
    &.secondary {
        color: $blue;
        border: 2px solid $blue;
        background: #cde9f1;
    }
    &.tertiary {
        color: $orange;
        border: 2px solid $orange;
        background: #fde6bc;
    }
    &.error {
        color: $red;
        border: 2px solid $red;
        background: #ffd0d0;
    }
    &.hollow {
    }
    &.greyed {
        background: #efefef;
        border-color: grey;
        color: grey;
    }
    &.small {
        font-size: 12px;
        padding: 4px 8px;
    }
    font-size: 14px;
}
