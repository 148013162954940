@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.greyout {
    background: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    z-index: 10;
    opacity: 0;
    &.active {
        opacity: 0.2;
        pointer-events: initial;
    }
}
.businessSwitcher {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;
    border-radius: 5px;
    z-index: 100;
    border: 1px solid transparent;

    &:hover,
    &.active {
        background: #fff;
    }

    .button {
        padding: 8px;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        .clicker {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            height: 48px;
            display: flex;
            padding-right: 8px;
            align-items: center;
            justify-content: right;
            bottom: 0;
        }

        .img {
            width: 32px;
            background-size: cover;
            background-position: center;
            height: 32px;
            min-width: 32px;
            margin-right: 16px;
            border-radius: 4px;
            overflow: hidden;
        }
    }

    .item {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        border-bottom: 1px solid #efefef;
        padding: 6px;
        cursor: pointer;
        &:hover,
        &.active {
            background: #efefef;
        }
        span {
            pointer-events: none;
            user-select: none;
        }
        .img {
            width: 24px;
            background-size: cover;
            background-position: center;
            height: 24px;
            min-width: 24px;
            margin-right: 8px;
            border-radius: 4px;
            overflow: hidden;
        }
    }

    .list {
        height: 0;
        overflow: hidden;
        padding: 0 8px;
        transition: all 0.2s ease-in-out;
        font-size: 14px;

        small {
            width: 100%;
            font-size: 12px;
            text-align: center;
            margin-bottom: 8px;
            pointer-events: none;
            color: #3490dc;
        }
    }

    &.active {
        .list {
            height: 160px !important;
            overflow: auto;
            padding: 8px;
        }
        .button {
            border-top: 1px solid #cecece;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
