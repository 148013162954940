@import "../../../../styles/variables";
@import "../../../../styles/partials/_animation.module.scss";
@import "../../../../styles/mixins/breakpoint";

.listItem {
    display: inline-block;
    padding: 8px 8px;
    border: 1px solid #efefef;
    margin-bottom: 8px;
    border-radius: 5px;
    width: 100%;
    .jobBlock,
    .contractBlock,
    .statusBlock,
    .actionBlock {
        vertical-align: top;
        padding: 8px;
    }
    .jobBlock {
        display: inline-flex;
        align-items: center;
        width: 300px;
        .details {
            padding-left: 16px;
        }
    }
    .contractBlock {
        display: inline-block;
        width: 300px;
    }
    .statusBlock {
        display: inline-block;
    }
    .actionBlock {
        display: inline-block;
        float: right;
    }
    button {
        background: $red;
        height: 28px;
        width: 28px;
        margin-top: 0px;
        margin-left: 8px;
        border-bottom: #992a20 2px solid;
        margin-right: 0;
        padding: 0;
        svg {
            fill: white;
            max-width: 12px;
        }
    }
}
