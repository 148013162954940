@import "../../../../styles/variables";
@import "../../../../styles/partials/_animation.module.scss";
.profileMenu {
    display: flex;
    position: relative;
    cursor: pointer;
    margin-left: 16px;
    align-items: center;
    transition: $transition;
    strong {
        margin-left: 4px;
        color: $dark-grey;
    }
    &:hover {
        span {
            border: 3px solid $green600;
        }
    }
    span {
        padding: 3px;
        border-radius: 8px;
        background: $green;
        cursor: pointer;
        width: 38px;
        min-width: 38px;
        border: 3px solid $green;
        background-size: cover;
        height: 38px;
        display: inline-block;
        transition: $transition;
        img {
            width: 100%;
            border-radius: 100%;
        }
    }
    i {
        font-style: initial;
        font-size: 14px;
        margin-left: 8px;
    }
}
