@import "../variables";
@import "../mixins/breakpoint";

.container {
    margin: 1.6rem;

    @include breakpoint($tablet) {
        width: calc(100% - 50px);
        margin: 0 auto 50px;
        max-width: 960px;
    }
}

.dropdown {
    float: right;
    text-align: right;
    position: relative;
    z-index: 10;
    font-size: 14px;
    span {
        border: 1px solid $blue;
        transition: all 0.2s ease-in-out;
        color: $blue;
        svg {
            path {
                stroke: $blue;
            }
        }
        padding: 8px 16px;
        border-radius: 5px;
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;

        svg {
            margin-left: 10px;
            path {
                transition: all 0.2s ease-in-out;
            }
        }
        &.open {
            border: 1px solid $blue;
            color: white;
            background: $blue;
            svg {
                path {
                    stroke: white;
                }
            }
        }
        &:hover {
            background: $blue;
            color: white;
            svg {
                path {
                    stroke: white;
                }
            }
        }
    }
    button,
    a {
        padding: 6px 8px;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;

        user-select: none;
        margin: 0;
        line-height: 14px;
        vertical-align: top;
        color: black;
        font-size: 14px !important;
        border-radius: 3px;
        background: white;
        &:hover {
            background: #f1f1f1;
        }
    }
    ul {
        position: absolute;
        top: 40px;
        right: 0;
        width: 182px;
        background: white;
        border: 1px solid grey;
        margin: 0;
        padding: 0 8px;

        margin-top: 8px;
        border-radius: 5px;
        list-style: none;
        display: none;
        &.open {
            display: block;
        }
        li {
            border-bottom: 1px solid #dadee8;
            padding: 6px 0;
            &.create {
                a {
                    i {
                        font-style: normal;

                        border-radius: 100%;
                        background: $green;
                        width: 20px;
                        line-height: 18px;
                        font-size: 16px;
                        text-align: center;
                        color: white;
                        margin-right: 8px;
                        height: 20px;
                        display: inline-block;
                    }
                }
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.filter {
    background: #efefef;
    padding: 8px;
    border-radius: 5px;

    span {
        display: inline-block;
        float: left;
        margin-right: 8px;
        height: 32px;
        padding: 0 16px;
        line-height: 30px;
        cursor: pointer;
        display: block;
        vertical-align: top;
        user-select: none;
        border-radius: 4px;
        color: grey;
        font-size: 14px;
        width: 100px;
        text-align: center;
        border: 1px solid #efefef;
        &:last-child {
            margin-right: 0;
        }
        &.active,
        &:hover {
            background: white;
            color: $black;
        }
        &.active {
            border: 1px solid $blue;
            background: $blue;
            color: white;
        }
    }
}
.blob_header {
    display: flex;
    width: 100%;
    padding: 24px 24px;
    align-items: center;
    justify-content: space-between;

    h3 {
        margin-bottom: 8px;
    }
    small {
        color: grey;
    }
}
.mobile_size {
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
}
.toolbar {
    padding: 16px 0;
    button {
        margin-right: 16px;
    }
}
.align_right {
    text-align: right;
}
.full {
    width: 100%;
}
.third {
    width: 100%;
    @include breakpoint($tablet) {
        width: 33.333%;
    }
}

.twoThird {
    @include breakpoint($tablet) {
        width: 66.666%;
    }
    width: 100%;
}
.half {
    @include breakpoint($tablet) {
        width: 50%;
    }
    width: 100%;
}
.quarter {
    @include breakpoint($tablet) {
        width: 25%;
    }
    width: 100%;
}
.threeQuarter {
    @include breakpoint($tablet) {
        width: 75%;
    }
    width: 100%;
}
.fakeForm {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.toolbelt {
    padding: 8px 24px;
    align-items: center;
}
.inputcontrol {
    position: relative;
    label {
        font-size: 12px;
        z-index: 10;
        left: 8px;
        background: white;
        position: absolute;
        top: -16px;
        padding: 4px 8px 0;
    }
}
.inactive {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
}
.component {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #cecece;
    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
    }
}
.mb8 {
    margin-bottom: 8px;
}
.mb16 {
    margin-bottom: 16px;
}
.controller {
    justify-content: center;
    flex-direction: row !important;
    display: flex !important;
    @include breakpoint($tablet) {
        button {
            display: inline-block;
        }
    }
}

.first {
    order: -1;
}
.button_span {
    display: inline-block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    column-gap: 20px;
    margin-bottom: 16px;
    position: relative;

    a,
    button {
        width: 100%;
        margin-bottom: 16px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    @include breakpoint($tablet) {
        display: flex;
        flex-direction: row;
        a,
        button {
            margin-bottom: 0;
        }
    }
}
.row {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    column-gap: 20px;
    margin-bottom: 16px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
    @include breakpoint($tablet) {
        display: flex;
        flex-direction: row;
    }

    &.noGap {
        column-gap: 0;
    }
    &.noReflow {
        flex-direction: row;
        width: auto;
    }

    &.centre {
        justify-content: center;
    }

    &.centreVertically {
        align-items: center;
    }

    &.align_right {
        justify-content: right;
        text-align: right;
    }
}

.column {
    align-self: inherit;
    width: 100%;

    &.unsetWidth {
        width: unset;
    }
}

ul {
    &.blank {
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        li {
            list-style: none;
        }
    }
}
.subtitle {
    font-size: 24px;
    margin-bottom: 12px;
}

span {
    &.label {
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        display: inline-block;
    }
}

.maxout {
    max-width: 1260px;
    &_small {
        max-width: 800px;
    }
}

.coverall {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.chip {
    min-height: 2em;
    color: white;
    padding: 0 16px;
    line-height: 2em;
    min-width: 80px;
    width: auto;
    border-radius: 16px 16px 16px 16px;
    border-bottom: 2px solid #006a62;
    background: #019e3a;
    display: inline-block;
    margin-top: 8px;
    text-align: center;
    &.false {
        background: #faaf00;
        border-bottom: 2px solid #c59014;
    }
}

.powercard {
    padding: 32px 0px;
    small {
        margin-top: 8px;
        color: grey;
    }
}

.invoice_container {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #cecece;
    background: white;
}

.form_inner {
    padding: 16px;
    .form_header {
        margin-bottom: 16px;
        width: 100%;
        display: inline-block;
    }
    label {
        color: grey;
        font-size: 14px;
        margin-bottom: 4px;
        width: 100%;
        display: inline-block;
    }
}
