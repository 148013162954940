@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.profile {
    background: white;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    @include breakpoint($tablet) {
        min-width: 600px;
    }
    max-width: 1060px;
    .header {
        position: relative;
        width: 100%;
        .backdrop {
            background: $blue;
            height: 80px;
            display: inline-block;
            width: 100%;
            .favourite {
                padding: 16px;
                margin-top: 16px;
                margin-right: 16px;
                display: table;
                font-size: 30px;
                float: right;
                cursor: pointer;
                color: #faaf00;
            }
        }
        .content {
            margin-top: -60px;
            position: relative;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            display: flex;

            padding: 0 24px;
            .breakdown {
                margin-top: 0;
                padding: 24px;
                background: white;
                width: 100%;
                border-radius: 3px;
            }
            .headline {
                small {
                    margin: 8px 0;
                    color: grey;
                }
                .rating {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    i {
                        font-style: normal;
                        margin-left: 8px;
                        font-size: 14px;
                        color: $blue;
                    }
                }
            }
            .accolades {
                padding-top: 16px;
                width: 100%;
                display: inline-block;
                span {
                    background: $grey200;
                    border: 1px solid $grey400;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    display: inline-block;
                    font-size: 12px;
                    border-radius: 3px;
                    user-select: none;
                    padding: 4px 8px;
                    i {
                        vertical-align: top;
                        font-size: 10px;
                        font-style: normal;
                        margin-left: 4px;
                        background: white;
                        user-select: none;
                        width: 14px;
                        color: black;
                        height: 14px;
                        display: inline-block;
                        margin-top: 2px;
                        text-align: center;
                        border-radius: 100%;
                        line-height: 14px;
                    }
                    &.gold {
                        border-color: $gold400;
                        background: $gold200;
                        color: $gold600;
                    }
                    &.green {
                        border-color: $green;
                        background: $green200;
                        color: $green;
                    }
                }
            }
            .avatar {
                margin-right: 16px;
                width: 100%;
                height: 100%;
                display: inline-block;
                position: relative;
                background-size: cover;
                background-position: center;
                border-radius: 3px;
                border: 8px solid white;
                background-color: #eaeaea;
                overflow: hidden;
                max-width: 180px;
                svg {
                    width: 100%;
                    float: left;
                    opacity: 0;
                    height: auto;
                }
            }
        }
    }

    .brags {
        padding: 16px 32px;
        .inner {
            padding: 16px;
            border: 1px solid $blue;
            border-radius: 5px;
            background: $blue400;
            display: flex;
            column-gap: 16px;
            .brag {
                padding: 16px;
                width: 25%;
                text-align: center;
                background: white;
                border: 1px solid $blue;
                border-radius: 5px;
                strong {
                    font-family: "Bagoss Condensed";
                    font-size: 40px;
                    display: inline-block;
                    width: 100%;
                    line-height: 40px;
                }
                small {
                    font-size: 14px;
                    color: grey;
                    margin: 0;
                }
            }
        }
    }
    .references {
        padding: 32px 32px 16px;
        width: 100%;
        .subtext {
            color: grey;
            margin-top: 4px;
            margin-bottom: 8px;
            font-size: 14px;
        }
        .avatar {
            width: 24px;
            margin-right: 10px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 8px 0;
                border-bottom: 1px solid #cecece;
                &:last-child {
                    border-bottom: 0;
                }
                small {
                    color: grey;
                }
                .content {
                    padding: 0 16px;
                    min-width: 260px;
                    max-width: 260px;
                }
            }
        }
        .smallest {
            font-size: 12px;
            margin-top: 0;
            line-height: 12px;
        }
        .validation {
            margin-left: auto;
            min-width: 150px;
            text-align: right;
            span {
                font-size: 12px;
                padding: 8px 16px;
                border: 1px solid #cecece;
                background: #efefef;
                border-radius: 5px;
                &.green {
                    background: $green200;
                    border-color: $green;
                }
                &.gold {
                }
            }
        }
    }
}

@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.profile {
    background: white;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    @include breakpoint($tablet) {
        min-width: 600px;
    }
    max-width: 1060px;
    .header {
        position: relative;
        width: 100%;
        .backdrop {
            background: $blue;
            height: 80px;
            display: inline-block;
            width: 100%;
            .favourite {
                padding: 16px;
                margin-top: 16px;
                margin-right: 16px;
                display: table;
                font-size: 30px;
                float: right;
                cursor: pointer;
                color: #faaf00;
            }
        }
        .content {
            margin-top: -60px;
            position: relative;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            display: flex;

            padding: 0 24px;
            .breakdown {
                margin-top: 0;
                padding: 24px;
                background: white;
                width: 100%;
                border-radius: 3px;
            }
            .headline {
                small {
                    margin: 8px 0;
                    color: grey;
                }
                .rating {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    i {
                        font-style: normal;
                        margin-left: 8px;
                        font-size: 14px;
                        color: $blue;
                    }
                }
            }
            .accolades {
                padding-top: 16px;
                width: 100%;
                display: inline-block;
                span {
                    background: $grey200;
                    border: 1px solid $grey400;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    display: inline-block;
                    font-size: 12px;
                    border-radius: 3px;
                    user-select: none;
                    padding: 4px 8px;
                    i {
                        vertical-align: top;
                        font-size: 10px;
                        font-style: normal;
                        margin-left: 4px;
                        background: white;
                        user-select: none;
                        width: 14px;
                        color: black;
                        height: 14px;
                        display: inline-block;
                        margin-top: 2px;
                        text-align: center;
                        border-radius: 100%;
                        line-height: 14px;
                    }
                    &.gold {
                        border-color: $gold400;
                        background: $gold200;
                        color: $gold600;
                    }
                    &.green {
                        border-color: $green;
                        background: $green200;
                        color: $green;
                    }
                }
            }
            .avatar {
                margin-right: 16px;
                width: 100%;
                height: 100%;
                display: inline-block;
                position: relative;
                background-size: cover;
                background-position: center;
                border-radius: 3px;
                border: 8px solid white;
                background-color: #eaeaea;
                overflow: hidden;
                max-width: 180px;
                svg {
                    width: 100%;
                    float: left;
                    opacity: 0;
                    height: auto;
                }
            }
        }
    }

    .brags {
        padding: 16px 32px;
        .inner {
            padding: 16px;
            border: 1px solid $blue;
            border-radius: 5px;
            background: $blue400;
            display: flex;
            column-gap: 16px;
            .brag {
                padding: 16px;
                width: 25%;
                text-align: center;
                background: white;
                border: 1px solid $blue;
                border-radius: 5px;
                strong {
                    font-family: "Bagoss Condensed";
                    font-size: 40px;
                    display: inline-block;
                    width: 100%;
                    line-height: 40px;
                }
                small {
                    font-size: 14px;
                    color: grey;
                    margin: 0;
                }
            }
        }
    }
    .references {
        padding: 32px 32px 16px;
        width: 100%;
        .subtext {
            color: grey;
            margin-top: 4px;
            margin-bottom: 8px;
            font-size: 14px;
        }
        .avatar {
            width: 24px;
            margin-right: 10px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 8px 0;
                border-bottom: 1px solid #cecece;
                &:last-child {
                    border-bottom: 0;
                }
                small {
                    color: grey;
                }
                .content {
                    padding: 0 16px;
                    min-width: 260px;
                    max-width: 260px;
                }
            }
        }
        .smallest {
            font-size: 12px;
            margin-top: 0;
            line-height: 12px;
        }
        .validation {
            margin-left: auto;
            min-width: 150px;
            text-align: right;
            span {
                font-size: 12px;
                padding: 8px 16px;
                border: 1px solid #cecece;
                background: #efefef;
                border-radius: 5px;
                &.green {
                    background: $green200;
                    border-color: $green;
                }
                &.gold {
                }
            }
        }
    }
}

.question_list {
    padding: 8px 16px;
    border: 1px solid #cecece;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    ul {
        margin: 0 !important;
    }
    li.question {
        width: 100%;
        border-bottom: 1px solid #cecece !important;
        padding: 12px 0 !important;

        float: left;
        display: inline-block !important;
        width: 100%;
        vertical-align: top;
        &:last-child {
            border-bottom: none !important;
            margin-bottom: 0 !important;
        }
        .question_num {
            width: 48px;
            line-height: 24px;
            vertical-align: top;
            float: left;
            font-family: "Bagoss Condensed";
            font-size: 24px;
        }
        .qcontent {
            width: calc(100% - 56px) !important;
            float: left;
        }
        .q,
        .answer {
            width: 100%;
            display: inline-block;
        }
        .q {
            font-family: "Bagoss Condensed";
            float: left;
            vertical-align: top;
            line-height: 18px;
            font-size: 18px;
        }
        .answer {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
