@import "../../../styles/variables";
@import "../../../styles/partials/_animation.module.scss";
@import "../../../styles/mixins/breakpoint";

.submenu {
    min-width: 180px;
    background: white;
    border-radius: 4px;
    padding: 0px;
    border: 1px solid $grey;
    position: fixed;
    top: 60px;
    margin-top: 16px;
    right: 0;
    display: none;
    right: 10px;
    left: 10px;
    @include breakpoint($tablet) {
        top: 100%;
        right: 0;
        left: initial;
        position: absolute;
    }
    div:last-child {
        border-bottom: 0;
    }
    &.open {
        display: inline-block;
    }
}
.freeform {
    margin-top: 14px;
    cursor: default;
}
