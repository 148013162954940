@import "../../../../styles/variables";
@import "../../../../styles/partials/_animation.module.scss";
.notification_list {
    padding: 0px 8px 0 8px;
    min-width: 400px;
    z-index: 10000;
    .notice {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        cursor: default;
        width: 100%;
        display: inline-block;
        position: relative;
        padding: 8px 0;
    }
    .notification {
        padding: 8px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #cecece;
        text-align: left;
        &:last-child {
            border-bottom: none !important;
        }
        a {
            float: left;
            color: $green;
            font-size: 14px;
        }
        .notifier {
            font-weight: 600;
            width: 100%;
            vertical-align: top;
            display: inline-block;
            font-size: 12px;
            float: left;
        }
        .notitext {
            font-weight: 400;
            font-size: 14px;
            float: left;
            vertical-align: top;
        }
        img {
            margin-right: 16px;
            max-width: 48px;
            height: 48px;
            overflow: hidden;
            width: 100%;
            border-radius: 3px;
        }
    }
}

.mini_menu {
    width: 100%;
    font-size: 12px;
    text-align: right;
    border-top: 1px solid $grey;
    padding-top: 8px;
    padding: 8px 8px 8px 4px;

    a,
    small,
    p,
    span {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
}
