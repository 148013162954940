.multiSelect {
    position: relative;
    height: 48px;
    display: flex;
    padding: 6px 8px 6px 8px;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    label {
        font-size: 12px;
        background: white;
        position: absolute;
        top: -16px;
        padding: 4px 8px 0;
    }
    .arrow {
        margin-left: auto;
        color: #008531;
        border: 2px solid #019e3a;
        background: #cdf1da;
        border-radius: 100%;
        margin-left: 8px;
        width: 24px;
        height: 24px;
        text-align: center;
    }
}

.multiSelectInput {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    cursor: pointer;
}

.placeholder {
    color: #888;
    font-size: 14px;
    padding: 0 8px;
}

.chip {
    padding: 4px 16px 4px;
    margin: 2px;
    display: inline-block;
    line-height: auto;
    color: white;
    border-radius: 5px;
    color: #008531;
    border: 2px solid #019e3a;
    background: #cdf1da;
    font-size: 14px;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    max-height: 200px;

    width: 280px;
    overflow-y: auto;
    z-index: 1;
}

.dropdownItem {
    padding: 8px;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
    &.selected {
        background-color: #e0e0e0;
    }
    input {
        margin-right: 8px;
    }
}
